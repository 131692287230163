import { useSelector, useDispatch } from "react-redux"
import { Row, Col, Button } from "react-bootstrap"
import { useState } from "react"
import { PlayGroup } from "./group"
import { qualifyPlayer, disqualifyPlayer } from "../../../../../actions/event_page/play_tab/groups"
import { NextRoundModal } from "./next_round_modal"
import { selectQualifiedPlayers } from "../../../../../selectors/round"

export const Groups = ({groups, round, readOnly}) => {
  
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

const qualifiedPlayers = useSelector(state => selectQualifiedPlayers(state, round.id));

  const canContinue = qualifiedPlayers.length >= (round.nrQPlayers * groups.length) && groups.every((group) => !group.needsReview) 
  
  const handleSelect = (player) => {
    const group = groups.find(group => group.id === player.groupId)
    if (isQualified(player)) {
      dispatch(disqualifyPlayer(player, group))
    }
    else {
      dispatch(qualifyPlayer(player, group))
    }
  }

  const handleNextRound = () => {
    if(canContinue) {
      setShow(true);
    }
  }

  const isQualified = (player) => {
    const playerIdToCheck = player.id;

    return qualifiedPlayers.some(qualifiedPlayer => qualifiedPlayer.id === playerIdToCheck);
  }

  return( groups && (
    <>
      <Row className="playground-container">
        <Col md={12} xs={12} className="playgroups-container">
          <Row className="full-width justify-content-md-center">
            {
              groups.map((group, index) => {
                return(
                  <Col md={2} xs={12} className="group-container">
                    <div className="large-text">Grupa {index+1}</div>
                    <PlayGroup 
                      group={group} 
                      index={index + 1} 
                      key={index} 
                      isQualified={isQualified} 
                      selectPlayer={(player) => handleSelect(player)}
                      readOnly={readOnly}
                    />
                  {group.needsReview && <div className="small-text">*Clasamentul necesita revizuire</div>}
                  </Col>
                )
              })
            }
          </Row>
        </Col>
      </Row>
      {
        !readOnly && canContinue &&
        <Button 
          disabled={!canContinue} 
          className='pull-right primary-gradient margin-1' 
          onClick={handleNextRound}>
            Runda urmatoare
        </Button>
      }
      <NextRoundModal 
        nrOfPlayers={qualifiedPlayers.length} 
        show={show} 
        onClose={() => setShow(false)}
      />
    </>
  )
  )
}