import { SERVER_URL } from '../../server_config';
import { fetchWithToken } from '../../helpers/auth';
import { fetchEvents } from '../events_page';

export const FETCH_EVENT_DATA_STARTED = 'FETCH_EVENT_DATA_STARTED'
export const FETCH_EVENT_DATA_SUCCEEDED = 'FETCH_EVENT_DATA_SUCCEEDED'
export const FETCH_EVENT_DATA_FAILED = 'FETCH_EVENT_DATA_FAILED'
export const FETCH_EVENT_TYPES_STARTED = 'FETCH_EVENT_TYPES_STARTED'
export const FETCH_EVENT_TYPES_SUCCEEDED = 'FETCH_EVENT_TYPES_SUCCEEDED'
export const FETCH_EVENT_TYPES_FAILED = 'FETCH_EVENT_TYPES_FAILED'

export const RESET_ERROR_STATE = 'RESET_ERROR_STATE'

export const resetErrorState = () => ({
  type: RESET_ERROR_STATE
})

export const fetchEventDataStarted = () => ({
  type: FETCH_EVENT_DATA_STARTED
})

export const fetchEventDataSucceeded = (response) => ({
  type: FETCH_EVENT_DATA_SUCCEEDED,
  event: response.event,
  registration: response.registration,
})

export const fetchEventDataFailed = error => ({
  type: FETCH_EVENT_DATA_FAILED,
  error
})

export const fetchEventTypesStarted = () => ({
  type: FETCH_EVENT_TYPES_STARTED
})

export const fetchEventTypesSucceeded = (eventTypes) => ({
  type: FETCH_EVENT_TYPES_SUCCEEDED,
  eventTypes
})

export const fetchEventTypesFailed = error => ({
  type: FETCH_EVENT_TYPES_FAILED,
  error
})

export const fetchEventData = (eventId) => {
  return async (dispatch) => {
    dispatch(fetchEventDataStarted());

    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const parsedResponse = await response.json(); // Parse the response body as JSON

      console.log(parsedResponse)
      dispatch(fetchEventDataSucceeded(parsedResponse));
    } catch (error) {
      dispatch(fetchEventDataFailed(error));
    }
  }
}

export const addEvent = (event) => {
  return async (dispatch) => {
    fetchWithToken(`${SERVER_URL}/events`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
    }).then(response => {
      if(!response.ok) {
        throw new Error('Network response was not ok');
      }
    })
    .then (() =>{
      dispatch(fetchEvents());
    });
  }
}
export const getPossibleEventTypes = () => {
  return async (dispatch, getState) => {
    // dispatch(fetchEventTypesStarted());
    const customerId = getState().auth.user.customerId;
    const queryString = `?customer_id=${customerId}`;
    try {
      const response = await fetch(`${SERVER_URL}/events/new${queryString}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const parsedResponse = await response.json();
      dispatch(fetchEventTypesSucceeded(parsedResponse));

      return parsedResponse;
    } catch (error) {
      dispatch(fetchEventTypesFailed(error));
      console.error('Error getting possible event types:', error);
    }
  }
}

export const editEvent = (eventId, updatedEvent) => {
  return async (dispatch) => {
    await fetchWithToken(`${SERVER_URL}/events/${eventId}`, {
      method: 'PUT', // Use PUT to update the event
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedEvent),
      credentials: "include",
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    }).then(() => {
      dispatch(fetchEvents());
    }).catch(error => {
      console.error('Error updating event:', error);
    })
  };
};

