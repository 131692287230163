import { fetchWithToken } from "../../helpers/auth";
import { SERVER_URL } from "../../server_config";

export const FETCH_EVENT_PLAYER_RESULTS_STARTED = "FETCH_EVENT_PLAYER_RESULTS_STARTED";
export const FETCH_EVENT_PLAYER_RESULTS_SUCCEEDED = "FETCH_EVENT_PLAYER_RESULTS_SUCCEEDED";
export const FETCH_EVENT_PLAYER_RESULTS_FAILED= "FETCH_EVENT_PLAYER_RESULTS_FAILED";
export const EDIT_EVENT_RESULT_FAILED = "EDIT_EVENT_RESULT_FAILED";
export const EDIT_EVENT_RESULT_STARTED = "EDIT_EVENT_RESULT_STARTED";
export const EDIT_EVENT_RESULT_SUCCEEDED = "EDIT_EVENT_RESULT_SUCCEEDED";


export const fetchEventPlayerResultsStarted = () => ({
  type: FETCH_EVENT_PLAYER_RESULTS_STARTED,
});

export const fetchEventPlayerResultsSucceeded = ({results}) => ({
  type: FETCH_EVENT_PLAYER_RESULTS_SUCCEEDED,
  results,
});

export const fetchEventPlayerResultsFailed = (error) => ({
  type: FETCH_EVENT_PLAYER_RESULTS_FAILED,
  error,
});

export const editEventResultStarted = () => ({
  type: EDIT_EVENT_RESULT_STARTED,
});

export const editEventResultSucceeded = (eventPlayerResult) => ({
  type: EDIT_EVENT_RESULT_SUCCEEDED,
  eventPlayerResult,
});

export const editEventResultFailed = (error) => ({
  type: EDIT_EVENT_RESULT_FAILED,
  error,
});

export const editEventResult = (eventId, resultId, pointsEarned) => {
  return async (dispatch) => {
    dispatch(editEventResultStarted());
    const response = await fetchWithToken(
      `${SERVER_URL}/events/${eventId}/event_player_results/${resultId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pointsEarned: pointsEarned }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      dispatch(editEventResultSucceeded(data.eventPlayerResult));
    } else {
      dispatch(editEventResultFailed("Error updating event result"));
      console.log("Error updating event result");
    }
  };
}

export const fetchEventPlayerResults = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const eventId = state.eventPage.event.id;

    if (state.customerDashboard.status === 'loading') {
      return;
    }

    fetchWithToken(`${SERVER_URL}/events/${eventId}/event_player_results`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "include",
    }).then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error('Failed to fetch event player results')
    }).then(data => {
      console.log(data)
      dispatch(fetchEventPlayerResultsSucceeded(data))
    }).catch(error => {
      dispatch(fetchEventPlayerResultsFailed(error.message))
      console.log(error);
    });
  };
}

export const confirmEventResults = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const eventId = state.eventPage.event.id;

    if (state.customerDashboard.status === 'loading') {
      return;
    }

    fetchWithToken(`${SERVER_URL}/events/${eventId}/confirm_results`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "include",
    }).then(response => {
      if (response.ok) {
        dispatch(fetchEventPlayerResults());
      }
      throw new Error('Failed to confirm results');
    }).catch(error => {
      console.log(error);
    });
  };
}