import { useDispatch } from "react-redux"
import { ListGroup, ListGroupItem, Button} from "react-bootstrap"
import { useState, useEffect } from "react"
import { deleteRound } from "../../../../actions/event_page/play_tab/rounds"
import { confirmGroups } from "../../../../actions/event_page/play_tab/groups"

export const EditPairingsForm = ({groups, roundId}) => {

  const [ firstSelectedPlayer, setFirstSelectedPlayer ] = useState()
  const [ modifiedPlayers, setModifiedPlayers ] = useState({})

  const [modifiedGroups, setModifiedGroups] = useState(groups);
  
  const dispatch = useDispatch();

  useEffect(() => {
    setModifiedGroups(groups);
  }, [groups]);

  const findGroupByPlayer = (newGroups, playerId) => {
    for (const group of newGroups) {

      const player = group.instances.find((p) => p.id === playerId);
      if (player) {
        return group;
      }
    }
    return null;
  }

  const switchPlayers = (player1, player2) => {

    var newGroups = JSON.parse(JSON.stringify(modifiedGroups));
    console.log('switching')
    console.log(newGroups)

    const group1 = findGroupByPlayer(newGroups, player1.id);
    const group2 = findGroupByPlayer(newGroups, player2.id);
  
    if (group1 && group2) {

      const player1Index = group1.instances.findIndex(player => player.id === player1.id);
      const player2Index = group2.instances.findIndex(player => player.id === player2.id);
  
      if (player1Index !== -1 && player2Index !== -1) {

        var temp1 = group1.instances[player1Index]
        var temp2 = group2.instances[player2Index]

        group2.instances[player2Index] = temp1;
        group1.instances[player1Index] = temp2;

        setModifiedGroups(newGroups);
        setFirstSelectedPlayer(null);
        setModifiedPlayers({
          ...modifiedPlayers, 
          [player1.id]: group2.id, 
          [player2.id]: group1.id,
        });
      } 
    }
  };

  const handleSelect = (player) => {
    firstSelectedPlayer !== undefined
    ?
      switchPlayers(firstSelectedPlayer, player)
    :
      setFirstSelectedPlayer(player)
  }

  const handleDeleteRound = () => {
    dispatch(deleteRound(roundId));
  }

  const handleConfirmGroups = () => {
    dispatch(confirmGroups(modifiedPlayers, roundId));
  }

  return(
    <>
      <div className="large-text margin-1 border-bottom-1 padding-bottom-1">
        Pentru a edita grupele, selecteaza un jucator si apoi selecteaza un alt jucator pentru a-i schimba locul.
      </div>
      <div className="groups-container">
        {
          modifiedGroups.map((group, index) => {
            return(
              <div className="group-container">
                <div className="medium-text bold">Grupa {index+1}</div>
                <ListGroup className="group">
                    {group.instances.map(player =>
                      <ListGroupItem  className={player === firstSelectedPlayer ? 'selected' : ''} action onClick={() => handleSelect(player)}>
                        <div className="medium-text">
                          {player.player.name}
                        </div>
                        <div className="pull-right small-text">
                          {player.player.city}
                        </div>
                      </ListGroupItem>
                  )}
                  </ListGroup>
              </div>
            )
          })
        }
      </div>
      <Button className="primary-gradient margin-1 pull-left medium-text" onClick={handleDeleteRound}>Inapoi</Button>
      <Button className="primary-gradient margin-1 pull-right medium-text" onClick={handleConfirmGroups}>Confirmare</Button>
    </>
  )
}