export const selectEventData = state => state.eventPage.event
export const selectEventDataStatus = state => state.eventPage.status
export const selectEventStage = state => state.eventPage.event.eventStageId
export const selectEventError = state => state.eventPage.error
export const selectCurrentEventManagers = state => state.eventPage.registration.managers
export const selectConfirmedPlayers = state => state.eventPage.registration.confirmedPlayers
export const selectWaitlistedPlayers = state => state.eventPage.registration.waitlistedPlayers
export const selectRejectedPlayers = state => state.eventPage.registration.rejectedPlayers
export const selectWinner = state => state.eventPage.event.winner
export const selectPossibleEventTypes = state => state.eventPage.eventTypes
export const selectEventPlayerResults = state => state.eventPage.results.eventPlayerResults
export const selectConfirmedPlayersCount = state => state.eventPage.registration.confirmedPlayers.length
export const selectWaitlistedPlayersCount = state => state.eventPage.registration.waitlistedPlayers.length
export const selectRejectedPlayersCount = state => state.eventPage.registration.rejectedPlayers.length
export const selectAveragePoints = state => state.eventPage.registration.averagePoints