import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import firstIcon from "../../images/management-home.png";
import secondIcon from "../../images/home-table-tenis.png";
import thirdIcon from "../../images/home-pie-chart.png";

export const OrganisationWidget = () => {
  return (
    <div className="home-section-container">
      <h2 className="accent-color margin-bottom-1">Ce face o organizatie?</h2>
      <Row className="home-row">
        <Col className="home-column">
          <div className='home-icon-container'>
            <img src={firstIcon} alt="Administrare" className="home-icon"/>
          </div>
          <div className="home-icon-text margin-bottom-1 bold">Administrare</div>
          <ul className="home-icon-list bold primary-color maring-top-1">
            <li> Adauga administratori</li>
            <li> Liste de asteptare</li>
            <li> Inscrieri</li>
          </ul>
        </Col>

        <Col className="home-column">
          <div className='home-icon-container'>
            <img src={secondIcon} alt="Turnee" className="home-icon"/>
          </div>
          <div className="home-icon-text margin-bottom-1 bold">Turnee</div>
          <ul className="home-icon-list bold primary-color maring-top-1">
            <li> Creare turnee</li>
            <li> Publicare turnee</li>
            <li> Istoric turnee</li>
          </ul>
        </Col>

        <Col className="home-column">
          <div className='home-icon-container'>
            <img src={thirdIcon} alt="Statistici" className="home-icon"/>
          </div>
          <div className="home-icon-text margin-bottom-1 bold">Statistici</div>
          <ul className="home-icon-list bold primary-color maring-top-1">
            <li> Puncte de sezon</li>
            <li> Clasamente</li>
          </ul>
        </Col>
      </Row>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button className="medium-text no-border accent margin-top-1 home-action-button bold">Creeaza o organizatie</Button>
      </div>
    </div>
  );
};
