import { fetchWithToken } from '../../helpers/auth';
import { SERVER_URL } from '../../server_config';

export const ADD_EVENT_TYPE_REQUEST = 'ADD_EVENT_TYPE_REQUEST';
export const ADD_EVENT_TYPE_SUCCESS = 'ADD_EVENT_TYPE_SUCCESS';
export const ADD_EVENT_TYPE_FAILURE = 'ADD_EVENT_TYPE_FAILURE';
export const FETCH_EVENT_TYPES_SUCCESS = 'FETCH_EVENT_TYPES_SUCCESS';
export const FETCH_EVENT_TYPES_FAILURE = 'FETCH_EVENT_TYPES_FAILURE';
export const FETCH_EVENT_TYPES_REQUEST = 'FETCH_EVENT_TYPES_REQUEST';
export const FETCH_EVENT_PLAYER_RESULTS_SUCCESS = 'FETCH_EVENT_PLAYER_RESULTS_SUCCESS';
export const FETCH_EVENT_PLAYER_RESULTS_FAILURE = 'FETCH_EVENT_PLAYER_RESULTS_FAILURE';
export const FETCH_EVENT_PLAYER_RESULTS_REQUEST = 'FETCH_EVENT_PLAYER_RESULTS_REQUEST';

export const addEventTypeRequest = () => ({
  type: ADD_EVENT_TYPE_REQUEST,
});

export const addEventTypeSuccess = (eventType) => ({
  type: ADD_EVENT_TYPE_SUCCESS,
  eventType,
});

export const addEventTypeFailure = (error) => ({
  type: ADD_EVENT_TYPE_FAILURE,
  payload: error,
});

export const fetchEventTypesRequest = () => ({
  type: FETCH_EVENT_TYPES_REQUEST,
});

export const fetchEventTypesSuccess = (eventTypes) => ({
  type: FETCH_EVENT_TYPES_SUCCESS,
  eventTypes,
});

export const fetchEventTypesFailure = (error) => ({
  type: FETCH_EVENT_TYPES_FAILURE,
  payload: error,
});

export const fetchEventPlayerResultsRequest = () => ({
  type: FETCH_EVENT_PLAYER_RESULTS_REQUEST,
});

export const fetchEventPlayerResultsSuccess = (eventPlayerResults) => ({
  type: FETCH_EVENT_PLAYER_RESULTS_SUCCESS,
  eventPlayerResults,
});

export const fetchEventPlayerResultsFailure = (error) => ({
  type: FETCH_EVENT_PLAYER_RESULTS_FAILURE,
  payload: error,
});

export const createEventType = (name) => {
  return async (dispatch, getState) => {
    const state = getState();
    const customerId = state.customerDashboard.customer.id;

    if (state.customerDashboard.status === 'loading') {
      return;
    }

    const eventType = {
      name: name,
    };

    dispatch(addEventTypeRequest());

    fetchWithToken(`${SERVER_URL}/customers/${customerId}/event_types`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ event_type: eventType }),
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Failed to create event type');
    }).then(data => {
      dispatch(addEventTypeSuccess(data.eventType));
    }
    ).catch(error => {
      dispatch(addEventTypeFailure(error.message));
    });
  };
}

export const fetchEventTypes = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const customerId = state.customerDashboard.customer.id;

    if (state.customerDashboard.status === 'loading') {
      return;
    }

    fetchWithToken(`${SERVER_URL}/customers/${customerId}/event_types`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "include",
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Failed to fetch event types');
    }).then(data => {
      dispatch(fetchEventTypesSuccess(data.eventTypes));
    }).catch(error => {
      dispatch(fetchEventTypesFailure(error.message));
    });
  };
}

export const editPointCalculationRule = (eventTypeId, rule) => {
  return async (dispatch, getState) => {
    const state = getState();
    const customerId = state.customerDashboard.customer.id;

    if (state.customerDashboard.status === 'loading') {
      return;
    }

    const body = {
      point_calculation_rule: rule,
    };

    fetchWithToken(`${SERVER_URL}/customers/${customerId}/event_types/${eventTypeId}/point_calculation_rules/${rule.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(body),
    }).then(response => {
      if (response.ok) {
        dispatch(fetchEventTypes());
      }
      throw new Error('Failed to edit point calculation rule');
    }).catch(error => {
      console.log(error);
    });
  };
}

export const editAverageCalculationRule = (eventTypeId, rule) => {
  return async (dispatch, getState) => {
    const state = getState();
    const customerId = state.customerDashboard.customer.id;

    if (state.customerDashboard.status === 'loading') {
      return;
    }

    const body = {
      average_calculation_rule: rule,
    };

    fetchWithToken(`${SERVER_URL}/customers/${customerId}/event_types/${eventTypeId}/average_calculation_rules/${rule.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(body),
    }).then(response => {
      if (response.ok) {
        dispatch(fetchEventTypes());
      }
      throw new Error('Failed to edit average calculation rule');
    }).catch(error => {
      console.log(error);
    });
  };
}
