import {
  FETCH_PROFILE_STARTED,
  FETCH_PROFILE_SUCCEEDED,
  FETCH_PROFILE_FAILED,
  FETCH_STATS_FAILED,
  FETCH_STATS_STARTED,
  FETCH_STATS_SUCCEEDED,
  FETCH_MATCHES_STARTED,
  FETCH_MATCHES_SUCCEEDED,
  FETCH_MATCHES_FAILED,
} from '../../actions/profile_page'

const initialState = {
  status: 'uninitialized',
  user: {},
  medals: {},
  nextEvents: [],
  pastEvents: [],
  stats: {},
  error: null,
}

export const profilePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_STARTED: {
      return {
        ...state,
        status: 'loading'
      }
    }
    case FETCH_PROFILE_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        user: action.profile.user,
        nextEvents: action.profile.nextEvents,
        customers: action.profile.customers,
      }
    }
    case FETCH_PROFILE_FAILED: {
      return {
        ...state,
        error: action.error
      }
    }
    case FETCH_STATS_STARTED: {
      return {
        ...state,
        status: 'loading'
      }
    }
    case FETCH_STATS_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        ...action.stats
      }
    }
    case FETCH_STATS_FAILED: {
      return {
        ...state,
        status: 'failed',
        stats: {},
        error: action.error
      }
    }
    case FETCH_MATCHES_STARTED: {
      return {
        ...state,
        pastEvents: state.pastEvents.map(event => {
          if (event.id === action.eventId) {
            return {
              ...event,
              status: 'loading',
            }
          }
          return event
        })
      }
    }
    case FETCH_MATCHES_SUCCEEDED: {
      return {
        ...state,
        pastEvents: state.pastEvents.map(event => {
          if (event.id === action.eventId) {
            return {
              ...event,
              status: 'success',
              matches: action.matches
            }
          }
          return event
        })
      }
    }
    case FETCH_MATCHES_FAILED: {
      return {
        ...state,
        pastEvents: state.pastEvents.map(event => {
          if (event.id === action.eventId) {
            return {
              ...event,
              status: 'failed',
              matches: []
            }
          }
          return event
        })
      }
    }
    default:
      return state
  }
}