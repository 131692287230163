import { ReactComponent as EditIcon } from '../../icons/edit.svg'
import { ReactComponent as AddIcon } from '../../icons/add.svg'
import { ReactComponent as DeleteIcon }  from '../../icons/delete.svg'
import { ReactComponent as RightIcon } from '../../icons/right.svg'
import { ReactComponent as LeftIcon } from '../../icons/left.svg'
import { ReactComponent as DownIcon } from '../../icons/down.svg'
import { ReactComponent as CheckIcon } from '../../icons/check.svg'
import { ReactComponent as CloseIcon } from '../../icons/close.svg'
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg'
import { ReactComponent as UsersIcon } from '../../icons/users.svg'
import { ReactComponent as HourglassIcon } from '../../icons/hourglass.svg'
import { ReactComponent as UserIcon } from '../../icons/user.svg'
import { ReactComponent as HamburgerIcon } from '../../icons/hamburger.svg'

const IconButton = ({icon, width, height, onClick, extraStyling, disabled, type='button'}) => {

  const iconComponents = {
    'edit': EditIcon,
    'add': AddIcon,
    'delete': DeleteIcon,
    'left': LeftIcon,
    'right': RightIcon,
    'down': DownIcon,
    'check': CheckIcon,
    'close': CloseIcon,
    'calendar': CalendarIcon,
    'users': UsersIcon,
    'hourglass': HourglassIcon,
    'user': UserIcon,
    'hamburger': HamburgerIcon,
  }

  const IconComponent = iconComponents[icon];

  const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
  };

  return (
    <button
      className={"center-content-v " + extraStyling}
      style={buttonStyle}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
    <IconComponent style={{width: width, height: height}}/>
    </button>
  );
}

export default IconButton;