import {
  FETCH_UPCOMING_EVENTS_FAILED,
  FETCH_UPCOMING_EVENTS_STARTED,
  FETCH_UPCOMING_EVENTS_SUCCEEDED
} from '../../actions/home_page'

const initialState = {
  status: 'uninitialized',
  events: [],
  error: null,
}

export const homePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UPCOMING_EVENTS_STARTED: {
      return {
        ...state,
        status: 'loading'
      }
    }
    case FETCH_UPCOMING_EVENTS_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        events: action.events,
      }
    }
    case FETCH_UPCOMING_EVENTS_FAILED: {
      return {
        ...state,
        status: 'failed',
        events: [],
        error: action.error
      }
    }
    default:
      return state
  }
}