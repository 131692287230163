import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import IconButton from '../../shared/icon_button';

export const LoggedOutDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton 
        icon='user'
        width={36}
        height={36}
        onClick={handleClick}
      >
        Login
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component="a" href="/login">Logare</MenuItem>
        <MenuItem onClick={handleClose} component="a" href="/register">Inregistrare</MenuItem>
      </Menu>
    </div>
  );
};