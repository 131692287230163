import { Modal, Button } from "react-bootstrap"
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { nextRound } from "../../../../../actions/event_page/play_tab/rounds"

export const NextRoundModal = ({nrOfPlayers, show, onClose}) => {
  
  const [ nrOfGroups, setNumberOfGroups] = useState();
  const [ higherGroups, setHigherGroups ] = useState(false);
  const [ numberOfQualifyingPlayers, setNumberOfQualifyingPlayers] = useState();
  const dispatch = useDispatch();
  const getGroupNrOptions = (number) => {
    if (number <= 0) {
      return []; // Return an empty array for non-positive numbers
    }

    const results = {
      1: '1',
    };
  
    for (let i = 2; i <= number / 2; i += 1) {
      results[i] = i.toString();
    }

    return results;
  }

  const getQualifyingPlayerOptions = (nrOfPlayers) => {
    const results = {}
    const maxPlayers = nrOfPlayers % nrOfGroups === 0 ? (nrOfPlayers / nrOfGroups) - 1 : (nrOfPlayers / nrOfGroups);

    for (let i = 1; i <= maxPlayers; i += 1) {
      results[i] = i.toString();
    }
    return results
  }

  const higherGroupsOptions = {
    'Da': true,
    'Nu': false,
  }
  
  const nrOfGroupOptions = getGroupNrOptions(nrOfPlayers);
  const nrOfQualifyingPlayerOptions = getQualifyingPlayerOptions(nrOfPlayers);

  const handleSubmit = () => {
    const newRound = {
      nrOfGroups: parseInt(nrOfGroups),
      nrQPlayers: parseInt(numberOfQualifyingPlayers),
      rules: higherGroups ? 'snake' : 'brackets',
    }
    dispatch(nextRound(newRound));
    onClose();
  }
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Grupe Superioare</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl className="center-content">
          <FormLabel className="medium-text text-center">Doriti sa creati grupe superioare?</FormLabel>
          <RadioGroup
            row
            value={higherGroups}
            onChange={e => { setHigherGroups(e.currentTarget.value === 'true') } }
            style={{ display: "flex", justifyContent: "center" }}>
            {Object.keys(higherGroupsOptions).map((option, index) => {
              return (
              <FormControlLabel
                key={index}
                value={higherGroupsOptions[option]}
                control={<Radio />}
                label={option}
                labelPlacement="top" />
              )
            })}
          </RadioGroup>   
        </FormControl>
        {higherGroups && (
          <>
            <FormControl className="center-content">
              <FormLabel className="medium-text text-center">In cate grupe doriti sa fie impartiti cei {nrOfPlayers} Jucatori?</FormLabel>
              <br></br>
              <RadioGroup
                row
                value={nrOfGroups}
                onChange={e => setNumberOfGroups(e.currentTarget.value)}
                style={{ display: "flex", justifyContent: "center" }}>
                {Object.keys(nrOfGroupOptions).map((option, index) => {
                  return (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={<Radio />}
                    label={nrOfGroupOptions[option]}
                    labelPlacement="top" />
                  )
                })}
              </RadioGroup>
            </FormControl>
            <FormControl className="center-content">
              <FormLabel className="medium-text text-center">Cati dintre jucatori se califica din fiecare grupa?</FormLabel>
              <br></br>
              <RadioGroup
              row
              value={numberOfQualifyingPlayers}
              onChange={e => setNumberOfQualifyingPlayers(e.currentTarget.value)}
              style={{ display: "flex", justifyContent: "center" }}>
              {Object.keys(nrOfQualifyingPlayerOptions).map((option) => {
                return (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={nrOfQualifyingPlayerOptions[option]}
                  labelPlacement="top"
                  style={{ margin: "0 10px" }}
                />
                );
              })}
              </RadioGroup>
            </FormControl>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className='accent no-border' onClick={onClose}>Anuleaza</Button>
        <Button className='primary-gradient no-border' onClick={() => { handleSubmit(higherGroups, nrOfGroups, numberOfQualifyingPlayers)}}>Runda urmatoare</Button>
      </Modal.Footer>
    </Modal>
  )
}