import { Row, Col } from 'react-bootstrap';
import gold from '../../../images/gold-medal.png';
import silver from '../../../images/silver-medal.png';
import bronze from '../../../images/bronze-medal.png';

export const Medals = ({medals}) => {

  const imagePaths = {
    'gold': gold,
    'silver': silver,
    'bronze': bronze,
  }
  return (
    <>
      <div className='accent-color xl-text extra-bold margin-bottom-1'>
        Medalii castigate
      </div>
      {
        Object.keys(medals).map(key => {
          const medalCount = medals[key];
          return (
            <Row className='medal-row'>
              {
                Array.from({length: medalCount}, (_, i) => (
                  <Col className='margin-bottom-1'>
                    <img className='medal-icon' src={imagePaths[key]} alt={key} key={i}/>
                  </Col>
                ))
              }
            </Row>
          )
        })
      }
    </>
  )
}