import { SERVER_URL } from "../server_config";
import { fetchWithToken } from "../helpers/auth";

export const FETCH_UPCOMING_EVENTS_STARTED = "FETCH_UPCOMING_EVENTS_STARTED";
export const FETCH_UPCOMING_EVENTS_SUCCEEDED = "FETCH_UPCOMING_EVENTS_SUCCEEDED";
export const FETCH_UPCOMING_EVENTS_FAILED = "FETCH_UPCOMING_EVENTS_FAILED";

export const fetchUpcomingEventsStarted = () => ({
  type: FETCH_UPCOMING_EVENTS_STARTED
});

export const fetchUpcomingEventsSucceeded = events => ({
  type: FETCH_UPCOMING_EVENTS_SUCCEEDED,
  events
});

export const fetchUpcomingEventsFailed = error => ({
  type: FETCH_UPCOMING_EVENTS_FAILED,
  error
});

export const fetchUpcomingEvents = () => {
  return async dispatch => {
    dispatch(fetchUpcomingEventsStarted());

    try {
      const response = await fetchWithToken(`${SERVER_URL}/events/upcoming`);
      const parsed_response = await response.json();
      dispatch(fetchUpcomingEventsSucceeded(parsed_response.data));
    } catch (error) {
      dispatch(fetchUpcomingEventsFailed(error));
    }
  };
};