import { fetchWithToken } from '../../helpers/auth';
import { SERVER_URL } from '../../server_config';

export const ADD_MANAGER_SUCCESS = 'ADD_MANAGER_SUCCESS';
export const ADD_MANAGER_FAILURE = 'ADD_MANAGER_FAILURE';
export const ADD_MANAGER_REQUEST = 'ADD_MANAGER_REQUEST';
export const REMOVE_MANAGER_SUCCESS = 'REMOVE_MANAGER_SUCCESS';
export const REMOVE_MANAGER_FAILURE = 'REMOVE_MANAGER_FAILURE';
export const REMOVE_MANAGER_REQUEST = 'REMOVE_MANAGER_REQUEST';

export const addManagerRequest = () => ({
  type: ADD_MANAGER_REQUEST,
});

export const addManagerSuccess = (newManager) => ({
  type: ADD_MANAGER_SUCCESS,
  newManager,
});

export const addManagerFailure = (error) => ({
  type: ADD_MANAGER_FAILURE,
  payload: error,
});

export const removeManagerRequest = () => ({
  type: REMOVE_MANAGER_REQUEST,
});

export const removeManagerSuccess = (removedManager) => ({
  type: REMOVE_MANAGER_SUCCESS,
  removedManager,
});

export const removeManagerFailure = (error) => ({
  type: REMOVE_MANAGER_FAILURE,
  payload: error,
});

export const addManager = (userId) => async (dispatch, getState) => {
  const state = getState();
  const customerId = state.customerDashboard.customer.id;

  if (state.customerDashboard.status === 'loading') {
    return;
  }

  dispatch(addManagerRequest());

  try {
    const response = await fetchWithToken(`${SERVER_URL}/customers/${customerId}/add_manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ new_manager_id: userId }),
    });

    if (response.ok) {
      const responseData = await response.json();
      dispatch(addManagerSuccess(responseData.newManager));
    } else {
      throw new Error("Failed to add manager.");
    }
  } catch (error) {
    dispatch(addManagerFailure("An error occurred while adding manager."));
  }
}

export const removeManager = (userId) => async (dispatch, getState) => {
  const state = getState();

  if (state.customerDashboard.status === 'loading') {
    return
  }

  const customerId = state.customerDashboard.customer.id; // Access the customer ID from the store

  dispatch(removeManagerRequest());

  try {
    const response = await fetchWithToken(`${SERVER_URL}/customers/${customerId}/remove_manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ manager_id: userId }),
    });

    if (response.ok) {
      const responseData = await response.json();
      dispatch(removeManagerSuccess(responseData.removedManager));
    } else {
      throw new Error("Failed to remove manager.");
    }
  } catch (error) {
    console.error("Error removing manager:", error);
    dispatch(removeManagerFailure("An error occurred while removing manager."));
  }
};

