import { Form, Row, Col, Button} from "react-bootstrap"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { editPointCalculationRule } from "../../../actions/customer_dashboard/season_points"

export const PointCalculationRulesForm = ({rules}) => {
  const [goldPointsCount, setGoldPointsCount] = useState(rules.goldPointsCount)
  const [silverPointsCount, setSilverPointsCount] = useState(rules.silverPointsCount)
  const [bronzePointsCount, setBronzePointsCount] = useState(rules.bronzePointsCount)
  const [defaultPoints, setDefaultPoints] = useState(rules.defaultPoints)
  const dispatch = useDispatch()

  const handleSubmit = () => {
    const newRules = {
      id: rules.id,
      goldPointsCount: goldPointsCount,
      silverPointsCount: silverPointsCount,
      bronzePointsCount: bronzePointsCount,
      defaultPoints: defaultPoints
    }
    dispatch(editPointCalculationRule(rules.eventTypeId, newRules))
  }

  return (
    <>
      <Form>
        <Row>
          <Col>
            <Form.Label className="medium-text">Locul 1</Form.Label>
            <Form.Control
              className="margin-top-1 margin-bottom-1"
              size="md"
              type="number"
              step="1"
              value={goldPointsCount}
              onChange={(e) => setGoldPointsCount(parseFloat(e.target.value))}
            />
          </Col>
          <Col>
            <Form.Label className="medium-text">Locul 2</Form.Label>
            <Form.Control
              className="margin-top-1 margin-bottom-1"
              size="md"
              type="number"
              step="1"
              value={silverPointsCount}
              onChange={(e) => setSilverPointsCount(parseFloat(e.target.value))}
            />
          </Col>
          <Col>
            <Form.Label className="medium-text">Locurile 3 si 4</Form.Label>
            <Form.Control
              className="margin-top-1 margin-bottom-1"
              size="md"
              type="number"
              step="1"
              value={bronzePointsCount}
              onChange={(e) => setBronzePointsCount(parseFloat(e.target.value))}
            />
          </Col>
          <Col>
            <Form.Label className="medium-text">Numar minim de puncte</Form.Label>
            <Form.Control
              className="margin-top-1 margin-bottom-1"
              size="md"
              type="number"
              step="1"
              value={defaultPoints}
              onChange={(e) => setDefaultPoints(parseFloat(e.target.value))}
            />
          </Col>
        </Row>
        <Button className="primary-gradient margin-top-1 pull-right" onClick={handleSubmit}>Salveaza</Button>
      </Form>
    </>
  )
}