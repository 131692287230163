import { Accordion, AccordionDetails, AccordionSummary, List, ListItem } from "@mui/material";
import { Row, Col, Button, Container } from "react-bootstrap";
import IconButton from "./shared/icon_button";

export const Roadmap = () => {
  return (
    <Container fluid>
      <div className="large-text margin-1 rounded-padded text-center border-bottom-1">
        Aceasta platforma pentru organizat turnee de tenis de masa este dezvoltata si intretinuta in timpul liber al unei singure persoane.
        De aceea, este posibil ca unele functionalitati sa nu fie complet implementate sau sa nu fie complet functionale. Orice feedback este binevenit la adresa de email dcercega@gmail.com.
      </div>
      <Row>
        <Col md={6} xs={12} className="margin-1">
          <div className="bold large-text margin-bottom-1 padding-bottom-1 center-content">Functionalitati planuite</div>
          <Accordion>
            <AccordionSummary className="medium-text">
            <IconButton type='submit' disable={true} height={18} width={18} transparent={true} icon={'check'}></IconButton>Evenimente private
            </AccordionSummary>
            <AccordionDetails className="small-text">
              Organizatorii vor putea crea evenimente private care nu vor fi vizibile pe pagina principala.
              Aceasta functionalitate va fi utila pentru organizarea de turnee private sau pentru testarea functionalitatilor platformei.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className="medium-text">
              <IconButton type='submit' disable={true} height={18} width={18} transparent={true} icon={'check'}></IconButton>
              Grupe Superioare
            </AccordionSummary>
            <AccordionDetails className="small-text">
              In majoritatea turneelor se creeaza si grupe superioare dupa cele inferioare. 
              Utilizatorul va fi intrebat daca se vor face grupe superioare dupa finalizarea grupelor inferioare.
              Impartirea pe grupe superioare se va face automat in functie de clasamentul din grupele inferioare.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className="medium-text">
              Sistem de puncte
            </AccordionSummary>
            <AccordionDetails className="small
              Jucatorii vor primi puncte in functie de rezultatele obtinute in turnee.-text">
              Aceste puncte vor fi folosite pentru a calcula clasamentul general al jucatorilor.
              Acordarea punctelor va putea fi personalizata de catre organizatorul turneului. Functionalitatile principale for fi bazate pe sistemul Amatur.
              <div className="pull-right margin-top-1">Estimare perioada implementare: Q2-2024</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className="medium-text">
              Divizii si restrictii de participare
            </AccordionSummary>
            <AccordionDetails className="small-text">
              Organizatorii vor putea crea divizii in cadrul turneelor si vor putea limita participarea jucatorilor la anumite divizii.
              Aceasta functionalitate va fi utila pentru organizarea de turnee cu jucatori de niveluri diferite. Aceasta functionalitate este o completare a sistemului de puncte.
              <div className="pull-right margin-top-1">Estimare perioada implementare: Q2-2024</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className="medium-text">
              Verificare Numar de Telefon
            </AccordionSummary>
            <AccordionDetails className="small-text">
              Inregistrarea unui cont va necesita verificarea unui numar de telefon.
              Acest lucru va impiedica crearea de conturi false si va asigura ca organizatorii de turnee pot contacta jucatorii.
              Verificarea se va face prin trimiterea unui cod de 6 cifre prin mesaj urmand ca utilizatorul sa il introduca in interfata web pentru a valida numarul de telefon.
              <div className="pull-right margin-top-1">Estimare perioada implementare: Q3-2024</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className="medium-text">
              Printare fise de joc
            </AccordionSummary>
            <AccordionDetails className="small-text">
              La majoritatea turneelor, organizatorii impart fise de joc fiecarei grupe pentru a introduce rezultatele meciurilor.
              Aceasta functionalitate va permite exportarea fiselor de joc in format PDF pentru a fi printate si folosite in cadrul turneelor.
              Fisele vor putea fi exportate pentru fiecare grupa individuala dar si pentru toate grupele in acelasi fisier.
              <div className="pull-right margin-top-1">Estimare perioada implementare: Q3-2024</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className="medium-text">
              Import si Export de Date
            </AccordionSummary>
            <AccordionDetails className="small-text">
              Utilizatorii vor putea importa si exporta datele jucatorilor in format CSV si XLS.
              Acest lucru va permite organizatorilor sa adauge rapid jucatori in baza de date si sa exporte datele pentru a le folosi in alte aplicatii.
              <div className="pull-right margin-top-1">Estimare perioada implementare: Q3-2024</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className="medium-text">
              Sistem de notificari
            </AccordionSummary>
            <AccordionDetails className="small-text">
              Utilizatorii vor primi notificari pe telefon pentru a le reaminti de turneele la care sunt inscrisi.
              Notificarile vor fi personalizabile si vor putea fi dezactivate de catre utilizator.
              <div className="pull-right margin-top-1">Estimare perioada implementare: Q4-2024</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className="medium-text">
              Recenzii organiztori si turnee
            </AccordionSummary>
            <AccordionDetails className="small-text">
              Utilizatorii vor putea lasa recenzii organizatorilor si turneelor la care au participat.
              <div className="pull-right margin-top-1">Estimare perioada implementare: Q4-2024</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className="medium-text">
              Plata cu cardul
            </AccordionSummary>
            <AccordionDetails className="small-text">
              Organizatorii vor putea seta o taxa de inscriere pentru turneele pe care le organizeaza iar utilizatorii vor putea plati acea taxa cu cardul prin intermediul platformei.
              Aceasta functionalitate va permite organizatorilor sa colecteze taxele de inscriere in avans si sa evite situatiile in care jucatorii nu se prezinta la turneu dupa ce s-au inscris.
              <div className="pull-right margin-top-1">Estimare perioada implementare: 2025</div>
            </AccordionDetails>
          </Accordion>
        </Col>
        <Col className="margin-1">
          <div className="bold large-text margin-bottom-1 padding-bottom-1 center-content">Donatii</div>
          <div className="medium-text rounded-padded white-bg">
            Mentinerea tehnica a acestei platforme are un cost lunar care este suportat din donatii si fonduri proprii.
            Daca consideri ca aceasta platforma este utila si doresti sa sustii dezvoltarea acesteia, poti contacta dezvoltatorul la adresa dcercega@gmail.com.
            <br></br>
            <Button disabled className="margin-top-1 primary-gradient">Buton de donatii in implementare</Button>
          </div>

        </Col>
      </Row>
    </Container>
  );
}