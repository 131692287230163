import { Accordion, Col, Row, Table} from "react-bootstrap"
import { Player } from "./player_component"
import { AddPlayerButton } from "./add_player_button"
import { selectEventError } from "../../../../selectors/event_page"
import { ErrorAlert } from "../../../shared/error_alert"
import { useDispatch, useSelector } from "react-redux"
import { resetErrorState } from "../../../../actions/events_page"
import { AddTestPlayersButton } from "./add_test_players_button"
import IconButton from "../../../shared/icon_button"
import { useEffect } from "react"
import { fetchPlayers } from "../../../../actions/event_page/registration"
import { selectEventData, selectConfirmedPlayers, selectRejectedPlayers, selectWaitlistedPlayers } from "../../../../selectors/event_page"  

export const PlayersTab = () => {

  const dispatch = useDispatch()

  const confirmedPlayers = useSelector(selectConfirmedPlayers)
  const waitlistedPlayers = useSelector(selectWaitlistedPlayers)
  const rejectedPlayers = useSelector(selectRejectedPlayers)
  const event = useSelector(selectEventData)

  const handleErrorClose = () => {
    dispatch(resetErrorState())
  }

  useEffect(() => {
    dispatch(fetchPlayers())
  }, [])

  return (
    <>
      <ErrorAlert errorSelector={selectEventError} onClose={handleErrorClose}/>
      <Row>
        <Col className="players-list">
          <div className="large-text margin-bottom-1 flex-container">
            <IconButton height={26} width={26} icon={'check'} disabled={true}></IconButton>
            Jucatori
            (
            {confirmedPlayers.length} 
            /
            {event.maxPlayers}
            )
          </div>
          <Table>
            <thead>
              <tr className="center-content-text-table">
                <th>#</th>
                <th>Nume</th>
                <th>Numar de telefon</th>
                <th>Oras</th>
                <th>Punctaj</th>
                <th>Actiuni</th>
              </tr>
            </thead>
            <tbody>
              {confirmedPlayers.map((player, index) => 
                <Player player={player} key={index} index={index + 1} variant='left' disabled={event.eventStageId !== 1}></Player>
              )}
            </tbody>
          </Table>
          <AddPlayerButton eventId={event.id} destination='player-list' disabled={event.eventStageId !== 1}></AddPlayerButton>
          <br></br>
          {confirmedPlayers.length === 0 &&<AddTestPlayersButton eventId={event.id} disabled={event.eventStageId !== 1}/>}
        </Col>
        <Col className="wait-list">
          <div className="large-text margin-bottom-1 flex-container">
            <IconButton height={26} width={26} icon={'hourglass'} disabled={true}></IconButton>
            Lista de asteptare
            (
              {waitlistedPlayers.length}
            )
          </div>
          <Table>
            <thead>
              <tr className="center-content-text-table">
                <th>#</th>
                <th>Nume</th>
                <th>Numar de telefon</th>
                <th>Oras</th>
                <th>Punctaj</th>
                <th>Actiuni</th>
              </tr>
            </thead>
            <tbody>
              {waitlistedPlayers.map((player, index) => 
                <Player player={player} key={index} index={index + 1} variant='right' disabled={event.eventStageId !== 1}></Player>
              )}
            </tbody>
          </Table>
          <AddPlayerButton eventId={event.id} destination='waitlist' disabled={event.eventStageId !== 1}></AddPlayerButton>
        </Col>
      </Row>
      <Row className="rejected-players-container">
        <Accordion defaultActiveKey={1} flush>
          <Accordion.Item>
            <Accordion.Header>
              <div className="large-text flex-container">
                <IconButton height={26} width={26} icon={'close'} disabled={true}></IconButton>
                Jucatori Respinsi
                (
                  {rejectedPlayers.length}
                )
              </div>
            </Accordion.Header>
            <Accordion.Body className="rejected-players-accordion">
              <Table>
                <thead>
                  <tr className="center-content-text-table">
                    <th>#</th>
                    <th>Nume</th>
                    <th>Numar de telefon</th>
                    <th>Oras</th>
                    <th>Punctaj</th>
                    <th>Actiuni</th>
                  </tr>
                </thead>
                <tbody>
                  {rejectedPlayers.map((player, index) => 
                    <Player player={player} key={index} index={index + 1} variant='down' disabled={event.eventStageId !== 1}></Player>
                  )}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </>
  )
}