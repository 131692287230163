import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEventPlayerResults } from '../../../../actions/event_page/event_results'
import { Button, Table } from 'react-bootstrap'
import { selectEventPlayerResults } from '../../../../selectors/event_page'
import { PlayerResult } from './player_result'
import { confirmEventResults } from '../../../../actions/event_page/event_results'

export const EventResultsTab = () => {

  const dispatch = useDispatch()
  const eventPlayerResults = useSelector(selectEventPlayerResults)
  const resultsLocked = eventPlayerResults && eventPlayerResults.some(result => result.confirmed)

  const handleConfirmResults = () => {
    dispatch(confirmEventResults())
  }

  useEffect(() => {
    dispatch(fetchEventPlayerResults())
  }, [dispatch])

  return (
    <div className='event-results-tab-container'>
      {
        eventPlayerResults && eventPlayerResults.length > 0 ? (
        <Table>
          <thead>
            <tr className="center-content-text-table">
              <th>#</th>
              <th>Nume</th>
              {!resultsLocked && <th>Punctaj Anterior</th>}
              <th>Puncte Castigate</th>
              {!resultsLocked && <th>Actiuni</th>}
            </tr>
          </thead>
          <tbody>
            {eventPlayerResults && eventPlayerResults.map((result, index) => {
              const nextResult = eventPlayerResults[index + 1];
              const hasDifferentPlace = nextResult && result.place !== nextResult.place;
              return(
                <PlayerResult key={result.id} index={index} result={result} hasDifferentPlace={hasDifferentPlace} resultsLocked={resultsLocked}></PlayerResult>
              )
            })}
          </tbody>
          {!resultsLocked && <Button className='pull-right primary-gradient margin-top-1' onClick={handleConfirmResults}>Confirma rezultate</Button>}
        </Table>
          ) : (
            <div className='text-center large-text margin-top-1'>Nu exista rezultate pentru turnee amicale</div>
          )
        }
    </div>
  );
}