import { Col, Form, Button} from "react-bootstrap"
import { useSelector } from "react-redux"
import { selectAuthenticated, selectUser} from "../../../selectors/users"
import { selectCurrentEventManagers } from "../../../selectors/event_page"
import { selectAllCurrentEventPlayers } from "../../../selectors/players"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { selfRegisterPlayer } from "../../../actions/events_page"

export const SignUpInfo = ({event}) => {

  const isAuthenticated = useSelector(selectAuthenticated)
  const players = useSelector(selectAllCurrentEventPlayers)
  const user = useSelector(selectUser)

  const dispatch = useDispatch()

  const currentUserRegistered = user ? players.some(player => player.phone === user.phone) : false;

  const registrationDisabled = currentUserRegistered || event.eventStageId > 1

  const managers = useSelector(selectCurrentEventManagers)
  const [name, setName] = useState(isAuthenticated ? user.name : '');
  const [phone, setPhone] = useState(isAuthenticated ? user.phone : '');
  const [city, setCity] = useState(isAuthenticated ? user.city : '');

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value)
  }

  const handleCityChange = (event) => {
    setCity(event.target.value)
  }

  const handleSignUp = (e) => {
    e.preventDefault();
    dispatch(selfRegisterPlayer({name, phone, city, userId: user?.id, eventId: event.id}));
  }

  return (
    <Col xs={12} md={4} className="detailed-event-column">
      <div className="large-text bold border-bottom-1 primary-gradient-color">Incheiere Inscrieri: {event.registrationCloseDate.slice(0,10)}, ora {event.registrationCloseDate.slice(11,16)}</div>
      <div className="medium-text bold margin-1">Contact Organizatori</div>
      <div className="border-bottom-1 padding-bottom-1">
        {
          managers.map((manager, index) => {
            return (
              <div className="medium-text margin-left-1" key={index}>{manager.first_name} {manager.last_name} - {manager.phone}</div>
            )
          })
        }
      </div>
      <div className="detailed-register-form">
        <Form>
          <Form.Label className='margin-bottom-1'>Nume</Form.Label>
          <Form.Control disabled={registrationDisabled || event.eventStageId > 1 } defaultValue={name} onChange={handleNameChange}></Form.Control>
          <Form.Label className='margin-bottom-1 margin-top-1'>Numar de telefon</Form.Label>
          <Form.Control disabled={registrationDisabled} defaultValue={phone} onChange={handlePhoneChange}></Form.Control>
          <Form.Label className='margin-bottom-1 margin-top-1'>Oras</Form.Label>
          <Form.Control disabled={registrationDisabled} defaultValue={city} onChange={handleCityChange}></Form.Control>
        </Form>
        <div className="d-flex justify-content-end">
          <Button disabled={currentUserRegistered || event.registration_closed} className="accent margin-top-1 no-border" onClick={handleSignUp}>
            {currentUserRegistered ? "Jucator Inscris" : (event.registration_closed ? "Inscrieri Inchise" : "Inscriere") }
          </Button>
        </div>
      </div>
      {event.needs_confirmation && <div className="small-text bold margin-1">
        * Odata inscrisi, jucatorii vor fi pusi pe lista de asteptare urmand sa fie confirmati de catre organizator
      </div>}
    </Col>
  )
}