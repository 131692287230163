import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PastEvent } from '../past_event';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatsForUser } from '../../../../actions/profile_page';
import { selectPastEvents, selectStats } from '../../../../selectors/profile_page';
import { Dropdown } from 'react-bootstrap';
import { AveragesChart } from './averages_chart';

export const UserStats = ({userId, possibleCustomers}) => {

  const dispatch = useDispatch();

  const [ customerId, setCustomerId ] = useState(Object.keys(possibleCustomers)[0]);
  const pastEvents = useSelector(selectPastEvents);
  const stats = useSelector(selectStats)

  const handleChangeCustomer = (eventKey) => {
    setCustomerId(eventKey)
    dispatch(fetchStatsForUser(userId, eventKey))
  }
  useEffect(() => {
    dispatch(fetchStatsForUser(userId, customerId))
  }
  , [customerId])

  return(
    <Row className="stats-container">
      <Row className='accent-color extra-bold xl-text margin-bottom-d5'>
        <Col md={6}>
          Statistica per organizatie
        </Col>
        { possibleCustomers &&
          <Col md={{span: 2, offset: 4}}>
            <Dropdown onSelect={(eventKey) => handleChangeCustomer(eventKey)}>
              <Dropdown.Toggle className='primary-gradient'>
                {possibleCustomers[customerId]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  Object.keys(possibleCustomers).map((id) => (
                    <Dropdown.Item key={id} eventKey={id}>{possibleCustomers[id]}</Dropdown.Item>
                  ))
                }
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        }
      </Row>
      <div className='small-text primary-color margin-bottom-1 extra-bold'>
        Total puncte: {stats.total}
        <br/>
        Medie puncte: {stats.average}
      </div>

      <div className="center-content-h">
        <AveragesChart monthlyAverages={stats.monthlyAverages}/>
      </div>
      <div className='accent-color extra-bold large-text margin-bottom-d5 margin-top-1'>
        Evenimente trecute
      </div>
      {
        pastEvents && pastEvents.length > 0
        ?
        pastEvents.map((event) => (
          <PastEvent key={event.id} event={event} userId={userId}/>
        ))
        :
        <div className="text-center margin-top-1">Nu exista eveniment trecut</div>
      }
    </Row>
  )
}