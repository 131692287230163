import { SERVER_URL } from "../server_config";
import { fetchWithToken } from "../helpers/auth";

export const FETCH_PROFILE_STARTED = "FETCH_PROFILE_STARTED";
export const FETCH_PROFILE_SUCCEEDED = "FETCH_PROFILE_SUCCEEDED";
export const FETCH_PROFILE_FAILED = "FETCH_PROFILE_FAILED";
export const FETCH_STATS_STARTED = "FETCH_STATS_STARTED";
export const FETCH_STATS_SUCCEEDED = "FETCH_STATS_SUCCEEDED";
export const FETCH_STATS_FAILED = "FETCH_STATS_FAILED";
export const FETCH_MATCHES_STARTED = "FETCH_MATCHES_STARTED";
export const FETCH_MATCHES_SUCCEEDED = "FETCH_MATCHES_SUCCEEDED";
export const FETCH_MATCHES_FAILED = "FETCH_MATCHES_FAILED";

export const fetchProfileStarted = () => ({
  type: FETCH_PROFILE_STARTED
});

export const fetchProfileSucceeded = profile => ({
  type: FETCH_PROFILE_SUCCEEDED,
  profile
});

export const fetchProfileFailed = error => ({
  type: FETCH_PROFILE_FAILED,
  error
});

export const fetchStatsStarted = () => ({
  type: FETCH_STATS_STARTED
});

export const fetchStatsSucceeded = stats => ({
  type: FETCH_STATS_SUCCEEDED,
  stats
});

export const fetchStatsFailed = error => ({
  type: FETCH_STATS_FAILED,
  error
});

export const fetchMatchesStarted = () => ({
  type: FETCH_MATCHES_STARTED
});

export const fetchMatchesSucceeded = (eventId, matches) => ({
  type: FETCH_MATCHES_SUCCEEDED,
  eventId,
  matches
});

export const fetchMatchesFailed = error => ({
  type: FETCH_MATCHES_FAILED,
  error
});

export const fetchUserData = (userId) => {
  return async dispatch => {
    dispatch(fetchProfileStarted());

    try {
      const response = await fetchWithToken(`${SERVER_URL}/users/${userId}`);
      const parsed_response = await response.json();
      dispatch(fetchProfileSucceeded(parsed_response));
    } catch (error) {
      dispatch(fetchProfileFailed(error));
    }
  };
};

export const fetchStatsForUser = (userId, customerId) => {
  return async dispatch => {
    fetchStatsStarted();
    try {
      const response = await fetchWithToken(`${SERVER_URL}/users/${userId}/stats?customer_id=${customerId}`);
      const parsed_response = await response.json();
      dispatch(fetchStatsSucceeded(parsed_response));
    } catch (error) {
      dispatch(fetchStatsFailed(error));
    }
  }
}

export const fetchMatchesForUser = (userId, eventId) => {
  return async dispatch => {
    fetchMatchesStarted();
    try {
      const response = await fetchWithToken(`${SERVER_URL}/users/${userId}/matches?event_id=${eventId}`);
      const parsed_response = await response.json();
      dispatch(fetchMatchesSucceeded(eventId, parsed_response.matches));
    } catch (error) {
      dispatch(fetchMatchesFailed(error));
    }
  }
}