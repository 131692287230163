import { InputGroup, Form } from "react-bootstrap"

export const ReadOnlyMatch = ({match}) => {
  return (
    <>
      <InputGroup className='padding-1'>
        <InputGroup.Text className="padding-1 match-player-name-left">{match.player1Name}</InputGroup.Text>
        <Form.Control size='sm' readOnly={true} className="text-center" value={match.player1Score} maxLength={1}/>
        <Form.Control size='sm' readOnly={true} className="text-center" value={match.player2Score} maxLength={1}/>
        <InputGroup.Text className="padding-1 match-player-name-right">{match.player2Name}</InputGroup.Text>
      </InputGroup>
    </>
  )
}