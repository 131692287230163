import { ListGroup, ListGroupItem, Row, Col, Form } from 'react-bootstrap';
import { ButtonGroup, Button } from '@mui/material';

export const BracketMatch = ({match, setScore, players, disabled, readOnly}) => {
  
  const isQualified = (playerId) => {
    return players.some(player => player.id === playerId && player.qualified)
  }

  return (
    <>
      <ListGroup className="group">
        <ListGroupItem className={isQualified(match.player1Id) ? 'selected' : ''}>
          <Row>
            <Col md={8} xs={7} className="medium-text">
              {match.player1Name || "BYE"}
            </Col>
            <Col md={4} xs={5} className="medium-text">
              <Form.Control 
                size='lg'
                readOnly={true}
                className={`text-center ${match.finished ? (isQualified(match.player1Id) ? 'selected-score' : 'no-border transparent') : 'transparent'}`}
                value={match.player1Score}
                maxLength={1}
                disabled={disabled}/>
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem className={isQualified(match.player2Id) ? 'selected' : ''}>
          <Row>
            <Col md={8} xs={7} className="medium-text">
              {match.player2Name || "BYE"}
            </Col>
            <Col md={4} xs={5}>
              <Form.Control
                size='lg'
                readOnly={true}
                className={`text-center ${match.finished ? (isQualified(match.player2Id) ? 'selected-score' : 'no-border transparent') : 'transparent'}`}
                value={match.player2Score}
                disabled={disabled}/>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
      {!disabled && !readOnly && !(players[0].bye || players[1].bye ) && <ButtonGroup className="center-content-h">
        <Button onClick={() => setScore(3,0)}>3-0</Button>
        <Button onClick={() => setScore(3,1)}>3-1</Button>
        <Button onClick={() => setScore(3,2)}>3-2</Button>
        <Button onClick={() => setScore(2,3)}>2-3</Button>
        <Button onClick={() => setScore(1,3)}>1-3</Button>
        <Button onClick={() => setScore(0,3)}>0-3</Button>
      </ButtonGroup>
      }
    </>
  );
}