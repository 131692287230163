import { useState } from "react"
import { useDispatch } from "react-redux"
import { editCustomer } from "../../../actions/customer_dashboard/customer_info"
import { Container, Form, Button } from "react-bootstrap"

export const CustomerInfoForm = ({customer}) => {

  const [ name, setName ] = useState(customer.name)
  const [ address, setAddress ] = useState(customer.address)

  const dispatch = useDispatch()

  const handleEditCustomer = () => {
    dispatch(editCustomer(customer.id, name, address))
  }

  return(
    <div className='customer-form-container'>
      <Form className='customer-form-wrapper padding-1'>
        <Form.Label className='large-text'>Numele Organizatiei</Form.Label>
        <Form.Control 
          className="margin-top-1 margin-bottom-1"
          size="lg"
          value={name}
          onChange = {(e) => {setName(e.target.value)}}/>
        <Form.Label className='large-text'>Adresa</Form.Label>
        <Form.Control 
          className="margin-top-1 margin-bottom-1"
          size="lg"
          value={address}
          onChange = {(e) => {setAddress(e.target.value)}}/>
        <Container flush className='d-flex justify-content-end margin-left-1'>
          <Button className="primary-gradient no-border" onClick={handleEditCustomer}>Salveaza</Button>
        </Container>
      </Form>
    </div>
    )
}

export default CustomerInfoForm