import React, { useEffect } from 'react'
import { Accordion, Table, Row, Col} from 'react-bootstrap'
import { fetchMatchesForUser } from '../../../actions/profile_page'
import { useDispatch, useSelector } from 'react-redux'
import { selectMatchesForEvent, selectMatchesStatus } from '../../../selectors/profile_page'

export const PastEvent = ({ userId, event }) => {

  const dispatch = useDispatch();

  const fetchMatches = () => {
    dispatch(fetchMatchesForUser(userId, event.id))
  }

  const matches = useSelector(state => selectMatchesForEvent(state, event.id))
  const status = useSelector(state => selectMatchesStatus(state, event.id))

  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={fetchMatches}>
          <Row style={{width: '100%'}}>
            <Col className='extra-bold primary-color' md={5}>
              {event.name}
            </Col>
            <Col className='extra-bold primary-color' md={3}>
              -
            </Col>
            <Col className='extra-bold primary-color' md={3}>
              Loc: {event.place}
            </Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          {
            status === 'loading'
            ? <div className='center-content-h'>
                Se incarca...
              </div>
            : matches && matches.length > 0
            ? 
              <Table>
                <tbody>
                  {matches.map(match => (
                    <tr>
                      <td className='text-center'>{match.player1Name}</td>
                      <td className='text-center'>{match.player1Score}</td>
                      <td className='text-center'>-</td>
                      <td className='text-center'>{match.player2Score}</td>
                      <td className='text-center'>{match.player2Name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            : <div className='center-content-h'>
                Nu exista meciuri
              </div>
          }
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}