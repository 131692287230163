import React, {useEffect, useRef} from 'react';
import { PlayGroup } from '../event_dashboard/play_tab/groups/group';
import { BracketPlayGroup } from '../event_dashboard/play_tab/brackets/bracket_group';
import { fetchGroupsForRound } from '../../../actions/event_page/play_tab/groups';
import { useDispatch } from 'react-redux';

const RoundInfo = ({round, index}) => {

  const isQualified = (player) => {
    return player.qualified;
  }

  const dispatch = useDispatch();
  const prevRoundRef = useRef();

  useEffect(() => {
    if (prevRoundRef.current !== round) {
      dispatch(fetchGroupsForRound(round));
      prevRoundRef.current = round;
    }
  }, [dispatch]);

  return (
    <div className='slide-container'>
              <div className='large-text bold primary-gradient-color'>{index === 0 ? 'Grupe' : 'Runda ' + (index + 1)}</div>
              <p>{round.index}</p>
              {round.groups.map((group, index) => 
                  round.rules === 'snake' ?
                  <PlayGroup group={group} index={index + 1} isQualified={isQualified} disabled={false} readOnly={true}/>
                  :
                  <BracketPlayGroup readOnly={true} key={index} group={group} isQualified={isQualified} disabled={true}/>
              )}
            </div>
  );
}

export default RoundInfo;