import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getManager, resetPotentialManager } from '../../../actions/users';
import { addManager } from '../../../actions/customer_dashboard/permissions';
import { useSelector } from 'react-redux';
import { potentialManager, potentialManagerStatus, potentialManagerError } from '../../../selectors/customer_dashboard';


const AddManagerModal = ({ show, close }) => {
  
  const newManager = useSelector(potentialManager);
  const status = useSelector(potentialManagerStatus);
  const error = useSelector(potentialManagerError);
  
  const [textFieldValue, setTextFieldValue] = useState(newManager ? newManager.phone : '');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(textFieldValue)) {
      setPhoneNumberError('Invalid phone number');
      return;
    }
    // Handle form submission logic here
    dispatch(getManager(textFieldValue));
  };

  const handleSubmit = () => {
    dispatch(addManager(newManager.id));
    dispatch(resetPotentialManager())
    close();
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^(?:(?:\+|00)40|0)(?:7\d{8})$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const handleClose = () => {
    dispatch(resetPotentialManager())
    close();
  }

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Adauga Administrator</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="textField">
            { newManager 
              ? 
              <Form.Label className='bold'>{newManager.first_name} {newManager.last_name}</Form.Label>
              : 
              <Form.Label>Numar de telefon</Form.Label>
            }
            <Form.Control
              type="text"
              value={textFieldValue}
              onChange={(e) => setTextFieldValue(e.target.value)}
              isInvalid={phoneNumberError}
              disabled = {newManager}
            />
            {
              !newManager && status !== 'failure' &&
              <Form.Label className='margin-top-1 bold'>
                * Un Administrator trebuie sa aiba deja un cont de utilizator
              </Form.Label>
            }
            {
              status === 'failure' && 
              <Form.Label className='margin-top-1 bold error-message'>
                {error}
              </Form.Label>
            }
            <Form.Control.Feedback type="invalid">
              {phoneNumberError}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className='primary-gradient no-border' onClick={ newManager ? handleSubmit : handleSearch}>
          { newManager ? 'Salveaza' : 'Cauta Utilizator' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddManagerModal;
