import { Button } from "react-bootstrap"
import { EditableGroupLeaderboard } from "./editable_group_leaderboard"
import { ReadOnlyMatch } from "./read_only_match"
import { useDispatch } from "react-redux"
import { confirmGroupLeaderboard } from "../../../../../actions/event_page/play_tab/groups"

export const Conflicts = ({finalDecisionInfo, group, closeModal}) => {

  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(confirmGroupLeaderboard(group));
    closeModal();
  }

  return (
    <div>
      <div className='small-text margin-1 padding-1 border-1 primary-color'>
        Nu am putut stabili automat clasamentul final, mai jos puteti vedea rezultatele directe ale jucatorilor.
        <br/>
        Va rugam ordonati jucatorii in functie de scorul direct si de seturi.
      </div>

      {
        finalDecisionInfo.map((conflict) => {
          return(
            <div className='border-bottom-dark-1 padding-bottom-1'>
              <div className="medium-text bold margin-1">Jucatori</div>

              <EditableGroupLeaderboard players={conflict['leaderboard']} group={group}/>
      
              <div className=" medium-text bold margin-1">Meciuri</div>
              {conflict['matches'].map((match) => (
                <ReadOnlyMatch key={match.index} match={match}/>
              ))}
            </div>
          )
        })
      }
      <Button className='pull-right margin-1 primary' onClick={handleClick} >
        Confirma Clasament
      </Button>

    </div>
  )
}