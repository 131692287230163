import { Button, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { BracketPlayGroup } from "./bracket_group"
import { selectGroups } from "../../../../../selectors/groups"
import { useEffect, useRef } from "react"
import { fetchGroupsForRound } from "../../../../../actions/event_page/play_tab/groups"
import { nextRound } from "../../../../../actions/event_page/play_tab/rounds"
import { selectQualifiedPlayers } from "../../../../../selectors/round"

export const Bracket = ({round, index, isCurrentRound}) => {

  const dispatch = useDispatch();

  const prevRoundRef = useRef();

  useEffect(() => {
    if (prevRoundRef.current !== round) {
      dispatch(fetchGroupsForRound(round));
      prevRoundRef.current = round;
    }
  }, [dispatch, round.groupsConfirmed]);

  const groups = useSelector(state => selectGroups(state, round.id));

  const qualifiedPlayers = useSelector(state => selectQualifiedPlayers(state, round.id));
  const canContinue = qualifiedPlayers.length >= (round.nrQPlayers * groups.length)

  const isQualified = (player) => {
    return player.qualified
  }

  const handleNextRound = () => {
    if(isCurrentRound) {
      const newRound = {
        rules: 'brackets',
      }
      dispatch(nextRound(newRound));
    }
  }

  return(
    <div className="bracket-container">
      <div className={`${isCurrentRound ? 'bold' : 'secondary-color'} medium-text`}>Runda {index + 2}</div>
      {
        groups.map(group => (<BracketPlayGroup group={group} isQualified={isQualified} disabled={!isCurrentRound} readOnly={false}></BracketPlayGroup>))
      }
      <Row>
        {isCurrentRound && canContinue && <Button className="margin-top-2 primary-gradient" onClick={handleNextRound}>Runda urmatoare</Button>}
      </Row>

    </div>
  )
}