import { OrganisationWidget } from "./organisation_widget"
import { UpcomingEvents } from "./upcoming_events"

export const HomePage = () => {
  return (
    <div className="home-page-container">
      <UpcomingEvents/>
      <OrganisationWidget/>
    </div>
  )
}