import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchCustomerData } from "../../actions/customer_dashboard/customer_info"
import EventsTable from "./events/customer_events_table"
import { selectCustomer, selectCustomerStatus } from "../../selectors/customer_dashboard"
import { LoadingPage } from "../shared/loading_page"
import { Tab, Nav } from "react-bootstrap"
import CustomerInfoForm from "./details/customer_info_form"
import { CustomerPermissionsRoot } from "./permissions/customer_permissions_root"
import { CustomerSeasonPointsRoot } from "./season_points/customer_season_points_root"

export const CustomerRoot = () => {

  const status = useSelector(selectCustomerStatus)
  const customer = useSelector(selectCustomer)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCustomerData())
  }, [dispatch])

  if (status === 'loading') return <LoadingPage/>
  if (status === 'error') return <div>Error</div>
  if (status === 'success')
    return (
      <div className="margin-1 customer-dashboard-container">
        <Tab.Container defaultActiveKey={2}>
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey={1} className='bold'>{customer.name}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={2}>Evenimente</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={3}>Permisiuni</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={4}>Puncte de sezon</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className='margin-1'>
            <Tab.Pane eventKey={1}>
              <CustomerInfoForm customer={customer}/>
            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              <EventsTable events={customer.events}></EventsTable>
            </Tab.Pane>
            <Tab.Pane eventKey={3}>
              <CustomerPermissionsRoot/>
            </Tab.Pane>
            <Tab.Pane eventKey={4}>
              <CustomerSeasonPointsRoot/>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    )

}
