import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { format, parseISO } from 'date-fns';

export const AveragesChart = ({monthlyAverages}) => {
  
  return(
    <ResponsiveContainer className='stats-chart' width="85%" height={300}>
      <LineChart data={monthlyAverages} margin={{ top: 5, bottom: 5 }}>
        <XAxis 
          axisLine={false}
          dataKey="time"
          tickLine={false}
          padding={{ left: 20, right: 20 }}
          color='#044983'
          tickFormatter={(tick) => format(parseISO(tick), "MMM")}
          interval={0} // Ensures all months are shown
          minTickGap={0} // Reduces spacing restrictions
        />
        <YAxis
          padding={{bottom:20}}
          tickLine={false}
          axisLine={false}
          width={30}
          color='#044983' />
        <Tooltip labelFormatter={(label) => format(parseISO(label), "dd/MM/yyyy")} />
        <Line 
          type="monotone"
          dataKey="mp"
          stroke="#044983"
          strokeWidth={3}
          dot={{fill: '#044983'}}
          activeDot={{r: 8}}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}