import { configureStore } from '@reduxjs/toolkit'
import { logger } from 'redux-logger';
import authReducer from './reducers/authentication'
import { persistStore, persistReducer } from 'redux-persist'; // Import persistStore
import persistConfig from './persistConfig'; // Import the persistConfig
import { fetchEvents } from '../actions/events_page';
import { homePageReducer } from './reducers/home_page';
import { eventsPageReducer } from './reducers/events_page';
import customerDashboardReducer from './reducers/customer_dashboard';

import { combineReducers } from 'redux'; 
import eventPageReducer from './reducers/event_page';
import { profilePageReducer } from './reducers/profile_page';

const rootReducer = combineReducers({
  auth: authReducer,
  homePage: homePageReducer,
  eventsPage: eventsPageReducer,
  customerDashboard: customerDashboardReducer,
  eventPage: eventPageReducer,
  profilePage: profilePageReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

const persistor = persistStore(store, {}, () => {
  // This callback is executed after rehydration is complete
  store.dispatch(fetchEvents());
});

export { store, persistor }