import { Row, Col } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { UpcomingEvent } from "../../home/upcoming_event"
import { useEffect } from "react";
import { fetchUserData} from "../../../actions/profile_page"
import { useParams } from "react-router-dom";
import { selectUserDetails, selectNextEvents, selectPastEvents, selectPossibleCustomers, selectMedals } from "../../../selectors/profile_page";
import SimpleBar from 'simplebar-react';
import { UserStats } from "./stats/root";
import { Medals } from "./medals";

export const ProfileRoot = () => {

  const user = useSelector(selectUserDetails)
  const medals = useSelector(selectMedals)
  const nextEvents = useSelector(selectNextEvents)
  const pastEvents = useSelector(selectPastEvents)
  const possibleCustomers = useSelector(selectPossibleCustomers)

  const { userId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserData(userId))
  }
  , [])

  return (
    <div className="profile-container">
      <Row className='details-container'>
        {/* <div className="center-content profile-picture-container ">
          Adauga fotografie profil
        </div> */}

        <Col className="details-section">  
          <div className='accent-color xl-text extra-bold'>
            Informatii generale
          </div>
          <div className='small-text primary-color margin-top-1 extra-bold'>
            Nume
            <br/>
            {user.first_name} {user.last_name}
          </div>
          <div className='small-text primary-color margin-top-1 extra-bold'>
            Varsta
            <br/>
            25
          </div>
          <div className='small-text primary-color margin-top-1 extra-bold'>
            Oras
            <br/>
            {user.city}
          </div>
        </Col>
        <Col className="medals-section">
          <Medals medals={medals}/>
        </Col>
      </Row>
      <Row className="next-events-container">
        <Col className='accent-color extra-bold xl-text' md={12}>
          Evenimente urmatoare
          <SimpleBar direction='rtl'>
            <div className='margin-top-1 margin-bottom-1 upcoming-events-container'>
              {
                nextEvents && nextEvents.length > 0
                ?
                nextEvents.map((event) => (
                  <UpcomingEvent key={event.id} event={event}/>
                ))
                :
                <div className="text-center margin-top-1">Nu exista evenimente</div>
              }
            </div>
          </SimpleBar>
        </Col>
      </Row>
      { possibleCustomers &&
        <UserStats userId={userId} pastEvents={pastEvents} possibleCustomers={possibleCustomers}/>
      }
    </div>
  )
}