import logo from '../../../images/logo_white.png'
import { Col, Row, Container, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { checkAuth, logout } from '../../../actions/users'
import { Footer } from '../footer'
import { LoggedOutDropdown } from './logged_out_dropdown'
import { LoggedInDropdown } from './logged_in_dropdown'
import { HamburgerDropdown } from './hamburger_dropdown'
import SimpleBar from 'simplebar-react'

export const HomeHeader = ({children}) => {

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

  const dispatch = useDispatch()


  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(logout())
  }

  const redirectToRegister = () => {
    window.location.href = '/register'
  }

  return (
    <>
      <SimpleBar style={{ maxHeight: '100vh' }}>
        <Container className="home-header-container" fluid >
          <Container className='header-background' fluid>
            <Row className='padding-top-1'>
              <Col md={4} xs={4} className='center-content-v'>
                <div className='left-content margin-left-1'>
                  <HamburgerDropdown />
                </div>
              </Col>
              <Col md={4} xs={4} className='center-content'>
                <a className='header-link' href='/'>
                  <img alt='top-spin-logo' src={logo} className='big-logo'></img>
                </a>
              </Col>
              <Col md={{span: 1, offset: 3}} xs={4} className='center-content-v'>
                <div className='right-content margin-right-1'>
                  {
                    isAuthenticated ? 
                    <LoggedInDropdown userId={user.id} customerId={user.customerId} handleLogout={handleLogout} />
                    :
                    <LoggedOutDropdown />
                  }

                </div>
              </Col>
            </Row>
            <Row className='bottom-header-row'>
              <Col md={4} xs={12} className='xxl-text bold white margin-left-1'>
                  Un Spatiu Digital Dedicat <br/>
                  Organizatorilor si <br/>
                  Jucatorilor de Tenis de Masa.
              </Col>
              <Col md={{span:4, offset: 3}} xs={12}>
                {
                  !isAuthenticated &&
                  <Button className='home-sign-up-button xl-text extra-bold pull-right' onClick={redirectToRegister}>
                    Inscrie-te acum
                  </Button>
                }
              </Col>
            </Row>
          </Container>
        </Container>
        {children}
        <Footer/>
      </SimpleBar>
    </>
  )
}
