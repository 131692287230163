import { Row, Col, Container } from "react-bootstrap"
import facebook from "../../images/facebook-logo.png"
import instagram from "../../images/instagram-logo.png"
import whatsapp from "../../images/whatsapp-logo.png"
import discord from "../../images/discord-logo.png"

export const Footer = () => {
  return (
    <Container fluid className="footer">
      <Row>
        <Col className="xs-text margin-left-1 bold no-padding center-content-v">
          <a href="mailto:dcercega@gmail.com">Contact: dcercega@gmail.com</a>
        </Col>
        <Col className="xs-text margin-1 no-padding white text-align-center">
          © 2025 Top-Spin.ro. Toate drepturile rezervate.
          <br></br>
          <a href="/privacy-policy">Politica de confidențialitate</a> | 
          <a href="/terms"> Termene și condiții</a>
          <div>
            <img src={facebook} alt="facebook" className="social-logo"/>
            <img src={instagram} alt="instagram" className="social-logo"/>
            <img src={whatsapp} alt="whatsapp" className="social-logo"/>
            <img src={discord} alt="discord" className="social-logo"/>
          </div>
        </Col>
        <Col className="xs-text no-padding margin-right-1 center-content-v ">
          <a className="text-align-right margin-1 header-link" href="/roadmap">Despre top-spin.ro</a>
        </Col>
      </Row>
    </Container>
  );
};
