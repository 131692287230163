import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import IconButton from '../../shared/icon_button';

export const LoggedInDropdown = ({userId, customerId, handleLogout}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton 
        icon='user'
        width={36}
        height={36}
        onClick={handleClick}
      >
        Login
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component="a" href={`/users/${userId}`}>Profil</MenuItem>
        <MenuItem onClick={handleClose} component="a" href="#">Setarile contului</MenuItem>
        <MenuItem onClick={handleClose} component="a" href={`/customers/${customerId}`}>Organizatia mea</MenuItem>
        <MenuItem onClick={handleLogout} component="a" href="/">Log Out</MenuItem>
      </Menu>
    </div>
  );
};