import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { AgGridReact } from 'ag-grid-react';
import { reorderPlayers } from "../../../../../actions/event_page/play_tab/groups";
import { useDispatch } from "react-redux";

export const EditableGroupLeaderboard = ({ players, group }) => {

  const dispatch = useDispatch();
  const columnDefs = [
    { headerName: 'Nume', field: 'player.name', flex: 4, rowDrag: true, },
    { headerName: 'SC', field: 'wonSets', flex: 1},
    { headerName: 'SP', field: 'lostSets', flex: 1},
    { headerName: 'Victorii', field: 'wonMatches', flex: 2,},
  ];

  const getReorderedRows = (event) => {
    const rowData = [];
    event.api.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  const onRowDragEnd = (event) => {
    const updatedRowData = getReorderedRows(event);
    dispatch(reorderPlayers(updatedRowData, group));
  };

  const getRowStyle = (rowData) => {
    if (rowData.data.qualified) {
      return { backgroundColor: 'var(--primary)', color: 'white' };
    }
  }

  return (
    <div className="ag-theme-alpine no-border">
      <AgGridReact
        columnDefs={columnDefs}
        rowData={players}
        domLayout='autoHeight'
        rowDragManaged={true}
        animateRows={true}
        onRowDragEnd={onRowDragEnd}
        getRowStyle={getRowStyle}
      />
    </div>
  );
}