import { Form, Button, Row, Col } from "react-bootstrap"
import { useState, useEffect } from "react"
import { createCustomer } from "../../actions/customer_dashboard/customer_info";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const CustomerForm = () => {

  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !address) {
      setError('Toate campurile sunt obligatorii');
      return
    }
    dispatch(createCustomer({name, address}))
  }

  const shouldRedirectToDashboard = useSelector((state) => state.customerDashboard.redirectToCustomerDashboard);

  const newCustomerId = useSelector((state) => state.auth.user.customerId);

  useEffect(() => {
    if (shouldRedirectToDashboard) {
      navigate(`/customers/${newCustomerId}`);
    }
  }, [newCustomerId, shouldRedirectToDashboard, navigate]);

  return(
    <div className='customer-form-container'>
      <Row className='customer-form-wrapper'>
        <Col md={6} xs={12} className='large-text padding-1'>
         <div className='bold margin-bottom-1'>
         Pentru a creea turnee, trebuie sa creezi o organizatie. Dupa accea vei putea:
          </div>
          <ul>
            <li>Stabili perioade de inscriere</li>
            <li>Adauga jucatori</li>
            <li>Forma grupe inferioare si superioare</li>
            <li>Introduce scoruri</li>
            <li>Creea tabel eliminatoriu</li>
          </ul>
        </Col>
        <Col md={6} xs={12}>
          <Form className="customer-form">
            <Form.Label className="large-text">Nume</Form.Label>
            <Form.Control size='lg' className="input" type="text" onChange={(e) => {setName(e.target.value)}}/>

            <Form.Label className="large-text">Adresa de email</Form.Label>
            <Form.Control size='lg' className="input" type="text" onChange={(e) => {setAddress(e.target.value)}}/>
            
            <Button size='lg' className="submit" onClick={handleSubmit}>Creeaza Organizatie</Button>
          </Form>
        </Col>
        {error && <div className='error-message center-content large-text padding-bottom-1'>{error}</div>}
      </Row>
    </div>
  )
}